<template>
  <div id="MessageInBox" class="wrap">
    <div class="formWrap">
      <h1 class="title">メッセージ - 送受信一覧</h1>
      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flex-wrap">
          <div class="searchShort inlineBlock searchSpace">
            <div>状況</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList">
                <template #first>
                  <b-form-select-option :value="''">-- 未選択 --</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchShort inlineBlock searchSpace">
            <div>タイプ</div>
            <div>
              <b-form-select
                v-model="search.type"
                :options="typeList">
                <template #first>
                  <b-form-select-option :value="''">-- 未選択 --</b-form-select-option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddleLong inlineBlock searchSpace">
            <div>タイトル</div>
            <div class="titleSearchWrap">
              <b-form-select
                class="h-50px recipient-select"
                v-model="search.title"
                :options="titleList">
                <template #first>
                  <b-form-select-option :value="''">-- 未選択 --</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-input
                v-if="search.title === '99'"
                v-model="search.other_title"
                placeholder="入力してください。"/>
            </div>
          </div>
        </div>
      </div>

      <div class="listBox">
        <div class="listWrap">
          <div class="contentsWidth mt-2">
            <div class="flex flex-between my-2">
              <b-pagination
                class="mb-1"
                aria-controls="fieldTable"
                :value="currentPage"
                @input="setCurrentPage($event)"
                :total-rows="totalRows"
                :per-page="perPage"
              />
              <div>
                <div class="inlineBlock mr-2">
                  {{nowPageCount}}件 / 全{{totalRows}}件
                </div>
                <div class="inlineBlock">
                  <span class="mr-2">表示件数</span>
                  <b-form-select
                    class="per-page-select"
                    :value="perPage"
                    @change="setPerPage"
                    :options="pageOptions"
                  />
                </div>
              </div>
            </div>
          </div>

          <div id="tableWrap" class="contentsWidth mt-2">
            <b-table striped hover
              id='fieldTable'
              table-class="uniAppMessageInboxTable"
              thead-class="tableHead"
              tbody-tr-class="dataWrap"
              :items="messageLogList"
              :fields="header"
              :filter="search"
              :filter-function="filtering"
              @filtered="onFiltered"
              show-empty
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:empty="scope">
                <p class="none-data">受信しているメッセージはありません。</p>
              </template>
              <template v-slot:emptyfiltered="scope">
                <div class="flex flexCenter">条件に一致するデータがありません。</div>
              </template>
              <template v-slot:cell(detail)="row">
                <b-link :to="'/university/message/inbox/detail/'+row.item.id">
                  開く
                </b-link>
              </template>
              <template v-slot:cell(reply)="row">
                <b-link v-show="row.item.type === '受信'" :to="'/university/message/inbox/replying/'+row.item.id">
                  返信
                </b-link>
              </template>
<!--               <template v-slot:cell(deleteBtn)="row">
                <b-link @click="messageRemove(row.item.id)">
                  削除
                </b-link>
              </template> -->
            </b-table>
          </div>

          <div class="contentsWidth mt-2" v-if="totalRows > 0">
            <div class="flex flex-between my-2">
              <b-pagination
                class="mb-1"
                aria-controls="fieldTable"
                :value="currentPage"
                @input="setCurrentPage($event)"
                :total-rows="totalRows"
                :per-page="perPage"
              />
              <div>
                <div class="inlineBlock mr-2">
                  {{nowPageCount}}件 / 全{{totalRows}}件
                </div>
                <div class="inlineBlock">
                  <span class="mr-2">表示件数</span>
                  <b-form-select
                    class="per-page-select"
                    :value="perPage"
                    @change="setPerPage"
                    :options="pageOptions"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import CONST_MESSAGE_SETTING from '@/constants/userMsgSetting';

export default {
  name: 'uniMessageInBox',
  components: {

  },
  data() {
    return {
      list: [],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      header: [
        {
          key: 'status',
          label: '状況',
          sortable: true,
          class: '',
        },
        {
          key: 'type',
          label: 'タイプ',
          sortable: true,
          class: '',
        },
        {
          key: 'send_date',
          label: '送受信日',
          sortable: true,
          class: '',
        },
        {
          key: 'from_name',
          label: '送信者氏名',
          sortable: true,
          class: '',
        },
        {
          key: 'to_name',
          label: '送信先氏名',
          sortable: true,
          class: '',
        },
        {
          key: 'title',
          label: '件名',
          sortable: true,
          class: '',
        },
        {
          key: 'detail',
          label: '',
          sortable: false,
          class: '',
        },
        {
          key: 'reply',
          label: '',
          sortable: false,
          class: '',
        },
        // {
        //   key: 'deleteBtn',
        //   label: '',
        //   sortable: false,
        //   class: '',
        // },
      ],
      sortBy: 'send_date',
      sortDesc: true,
      typeList: [
        { value: '送信', text: '送信' },
        { value: '受信', text: '受信' },
      ],
      isInit: true,
      oldPage: 1,
    };
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    await this.initData();
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
  computed: {
    search() {
      return this.$store.state.uniMessageSearch.search;
    },
    currentPage() {
      return this.$store.state.uniMessageSearch.currentPage;
    },
    totalRows() {
      return this.$store.state.uniMessageSearch.totalRows;
    },
    perPage() {
      return this.$store.state.uniMessageSearch.perPage;
    },
    recipientList() {
      return CONST_MESSAGE_SETTING.ADMIN_SEND_TARGET_TEXTS;
    },
    statusList() {
      return CONST_MESSAGE_SETTING.MESSAGE_STATUS_TEXTS;
    },
    titleList() {
      return CONST_MESSAGE_SETTING.MESSAGE_TITLE_TEXTS;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
    messageLogList() {
      const messageLogList = this.list.map((messageLog) => {
        const fromName = `${messageLog.sei || ''} ${messageLog.mei || ''}`;
        const titleType = (messageLog.title_type in this.titleList) ? this.titleList[messageLog.title_type] : this.titleList['99'];
        const setTitle = titleType === this.titleList['99'] ? `${titleType} - ${messageLog.title}` : titleType;
        return {
          id: messageLog.id,
          title: setTitle,
          sub_title: messageLog.title,
          send_date: moment(messageLog.send_date).format('YYYY/MM/DD HH:mm'),
          from_name: fromName,
          status: this.statusList[messageLog.status],
          type: messageLog.type,
          to_name: messageLog.to_name,
        };
      });
      return messageLogList;
    },
  },
  methods: {
    setCurrentPage(page) {
      this.$store.commit('uniMessageSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('uniMessageSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('uniMessageSearch/setTotalRows', value);
    },
    filtering(lineData, search) {
      const subTitleText = search.title === '99' ? search.other_title : '';
      const titleText = search.title !== '' ? this.titleList[search.title] : '';
      const status = search.status === '' || lineData.status === this.statusList[search.status];
      const type = search.type === '' || lineData.type === search.type;
      const title = titleText === '' || (lineData.title && lineData.title.includes(titleText));
      const subTitle = subTitleText === '' || (lineData.sub_title && lineData.sub_title.includes(subTitleText));
      return status && type && title && subTitle;
    },
    async initData() {
      const response = await api.send('/api/uni/message/reception')
        .catch((err) => {
          console.log(err);
        });
      this.list = response.data.list;
      this.totalRows = this.list.length;
    },
    onFiltered(filteredItems) {
      this.$store.commit('uniMessageSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('uniMessageSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('uniMessageSearch/setCurrentPage', 1);
      }
    },
    async messageRemove(id) {
      if (!await this.confirm('受信一覧からメッセージを削除します。\n送信者側のメッセージは削除されません。\nこのメッセージを削除しますか？')) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        id,
      };
      const response = await api.send('/api/uni/message/receptionRemove', param)
        .catch(async (err) => {
          await this.alert('メッセージの削除に失敗しました。');
          console.log(err);
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      this.alert('メッセージを送信履歴から削除しました。');
      await this.initData();
      this.$store.dispatch('page/offWaiting');
    },
  },
};
</script>
<style>
  #MessageInBox thead th:nth-of-type(1) {
    width: 100px !important;
  }
  #MessageInBox thead th:nth-of-type(2) {
    width: 105px !important;
  }
  #MessageInBox thead th:nth-of-type(3) {
    width: 110px !important;
  }
  #MessageInBox thead th:nth-of-type(4) {
    width: 160px !important;
    min-width: 160px;
  }
  #MessageInBox thead th:nth-of-type(5) {
    width: 160px !important;
    min-width: 160px;
  }
  #MessageInBox thead th:nth-of-type(7) {
    width: 60px !important;
  }
  #MessageInBox thead th:nth-of-type(8) {
    width: 60px !important;
  }
</style>
<style scoped>
  .title{
    color: #333333;
    font-weight: 300;
    font-size: 24px;
    margin: 0 100px;
    margin-top: 52px;
    border-bottom: solid 2px #A0A9D0;
    padding-left: 10px;
    padding-bottom: 7px;
  }
  .listBox{
    margin: 0 100px;
    margin-top: 53px;
  }
  .listWrap{
    width: 770px;
  }
  .none-data{
    text-align: center;
  }
  #serchWrap {
    margin: 0 100px;
  }
  #serchWrap input, #serchWrap select {
    height: 50px;
  }
  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }
  .searchLong {
    width: 450px;
  }

  .searchMiddleLong {
    width: 600px;
  }
  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
    margin-bottom: 5px;
  }
  .titleSearchWrap {
    display: flex;
  }
  .titleSearchWrap select{
    margin-right: 15px;
    width: 300px;
    min-width: 300px;
  }
</style>
